@import url('../../../styles/component.css');

/* todo - make sense of these colors */

#features {
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
    gap: 1.4rem;


}

@media screen and (min-width: 1024px) {
    #features {
        flex-direction: row;
    }
    #features>section {
        width: 50%;
    }
}


#add-ons li {
    list-style-type: "+ ";
}

#price {
    font-size: 3rem;
    font-weight: 500;
}

#price-duration {
    font-size: 1.5rem;
}

.plus-icon {
    font-size: 60%;
    padding: .1rem .2rem;
    background: #f2efec;
    position: relative;
    border-radius: 4px;
    bottom: .1rem;

}

ul {
    line-height: 1.4;
}


:host {
    display: block;
    color: #f2efec;
    font-size: 1.3rem;
    border-radius: 24px;
    max-width: 90%;
    min-width:60%;
    padding: 2rem;
    background: linear-gradient(180deg, #272727 0%, #403F3F 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}

@media screen and (min-width: 768px) {
    :host {
        min-width: unset;
        max-width: 80%;
    }
}

@media screen and (min-width: 1024px) {
    :host {
        min-width: unset;
        max-width: 65%;
    }
}


h2 {
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
    font-size: 3rem;
}

h3 {
    font-size: 1.5rem;
    padding-bottom: .5rem;
    margin-bottom: .5rem;
}

h2,
h3 {
    border-bottom: 1px solid #F9F6F2;
}

#features>section {
    width: 100%;
  
    flex-grow: 1;
    border-radius: 16px;
    padding: .8rem;
    border: 1px solid #656565;

    background: #3F3F3F;
}