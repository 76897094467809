<section>
    <h2>MVP CTO Subscription</h2>
    <div id="price">$995<span id="price-duration">/mo</span></div>
        <div id="features">
            <section>
                <h3>Included 📦</h3>
                <ul>
                    <li>45 min status meeting (1/mo)</li>
                    <li>MVP requirements scoping</li>
                    <li>Team strength / weakness assessment</li>
                    <li>Sprint Planning Reviews</li>
                    <li>Team recruitment / acquisition planning</li>
                    <li>Intellectual Property Strategy</li>
                    <li>Unlimited questions / support</li>
                    <li>Single User</li>
                </ul>

            </section>
            <section id="add-ons">
                <h3>Add-ons <span class="plus-icon">➕</span></h3>
                <ul>
                    <li>Additional Users - $295/user</li>
                    <li>Additional Status Meetings $245/each</li>
                    <li>Technical Sprint Specification (BYO Developers) - from $995/sprint</li>
                    <li>Expert Code Review (BYO Developers) - from $495/sprint</li>
                    <li>Sprint as a Service (includes Development & Expert Code Review) - from $4k/sprint</li>
                </ul>
            </section>
        </div>

</section>